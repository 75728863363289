'use client';
import styled, { css } from 'styled-components';
import { colors, mixins, toRgba, breakPoints } from '@/styles';

export const ReferralBanner = styled.div``;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: space-between;
  /* background: ${colors.semantic.notice.light}; */
  /* background: ${colors.semantic.information.light}; */

  background: ${colors.primary.white};
  padding: 20px;

  @media (max-width: ${breakPoints.mobile}) {
    flex-direction: column;
    gap: 10px;
    button {
      width: 100%;
    }
  }
`;

const animatedGradientWrapper = (glow: boolean, glowWidth: string = '2rem') => css`
  --border-angle: 0turn;

  &:not(.on) {
    z-index: 1;
  }

  &.on {
    border-radius: 5px;
    z-index: 0;

    & > * {
      border-radius: 3px;
      border: none;
    }

    --clr-1: #f87a7a;
    --clr-2: #f9947d;
    --clr-3: #f57ab1;
    --clr-4: #e07de8;
    --clr-5: #dbb2ed;
    --clr-6: #efb4c9;

    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: -2px;
      z-index: -1;

      background: conic-gradient(
        from var(--border-angle),
        var(--clr-1),
        var(--clr-2),
        var(--clr-3),
        var(--clr-4),
        var(--clr-5),
        var(--clr-6),
        var(--clr-1)
      );
      border-radius: inherit;
      background-position: center center;
      background-repeat: no-repeat;
      animation: bg-spin 2s linear infinite;
    }

    &:after {
      filter: ${glow ? `blur(${glowWidth})` : 'none'};
      opacity: 0.25;
    }

    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }
    &:hover {
      animation-play-state: paused;
    }
    @property --border-angle {
      syntax: '<angle>';
      inherits: true;
      initial-value: 0turn;
    }
  }
`;

export const AnimatedGradientWrapper = styled.div<{ $glow: boolean; $borderWidth?: number; $enabled: boolean }>`
  height: 100%;
  position: relative;

  & > * {
    position: relative;
  }
  ${({ $glow, $enabled }) => $enabled && animatedGradientWrapper($glow, '5px')};
`;
