'use client';

import Flash from '@/ui/Flash';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

const RedirectCallout: React.FC<{ redirect: string }> = ({ redirect }) => {
  const router = useRouter();
  const pathname = usePathname();
  const query = useSearchParams();
  const params = new URLSearchParams(query);

  const onClose = () => {
    params.delete('redirect');
    const newUrl = `${pathname}?${params.toString()}`;
    console.log('newUrl', newUrl);
    router.replace(newUrl);
  };

  return (
    redirect &&
    redirect !== pathname && (
      <Flash.Warning onClose={onClose}>
        <div>
          <div>
            <b>You've been redirected to your account home page. </b>{' '}
            <Link href={redirect} className="link-underline">
              Click here to go back to the page you signed up from
            </Link>
          </div>
        </div>
      </Flash.Warning>
    )
  );
};
export default RedirectCallout;
